export const languages = {
  en: {
    welcome: {
      title: `
          Hello, User!
              `,
      subTitle: `
              I'm Findus,
              your Email-Assistant
                  `,

      generateButton: "Generate Draft",
      footerText: "Made by H24 Development",
      footerSubText: "About Findus/H24",
    },
    draftEmail: {
      title: "This draft is generated by Generative AI, please review the content before sending.",
      agentInstructionLable: "Instructions for Assistant",
    },
    draftEmailButtons: {
      beautify: "Apply Instructions",
      generateAnswer: "Generate Answer",
      adoptDraft: "Paste Draft",
    },
    translateEmail: {
      revert: "Translate Back",
    },
    loader: {
      loading: "Loading ...",
    },
    prompts: {
      SpellCheck: "Spell Check",
      GrammarCheck: "Grammar Check",
      Beautify: "Beautify",
      OneMorePrompt: "One more prompt chip",
    },
    promptInput: {
      InputPlaceholder: "Type your prompt here",
    },
    Notification: {
      emptyPromptNotification: "There’s nothing to check here. Please, type a message in Outlook first.",
      irrelevantPromptNotification: "Please, ask relevant question.",
    },
    GeneratedDraft: {
      Response: "AI Response (Generated Draft)",
      inputPlaceholder: "Type prompt to regenerate response",
      InsertButton: "Insert Reply",
      WorkingButton: "Working on it ...",
      FooterText: " Made by H24 Development",
      FooterSubText: "About Findus/H24ai",
    },
    MenuBar: {
      Restart: "Restart",
      Report: "Report an issue",
      Refresh: "Refresh",
      ManageAddin: "Manage add-in",
    },
  },
  de: {
    welcome: {
      title: `
          Hallo, Benutzer!
              `,
      subTitle: `
              Ich bin Findus,
              Ihr E-Mail-Assistent
                  `,

      generateButton: "Entwurf Generieren",
      footerText: "entwickelt von H24",
      footerSubText: "Über Findus/H24",
    },
    draftEmail: {
      title: "Dieser Entwurf wurde von generativer KI erstellt, bitte überprüfen Sie den Inhalt vor dem Senden.",
      agentInstructionLable: "Anweisungen für den Assistenten",
    },
    draftEmailButtons: {
      beautify: "Anweisungen anwenden",
      generateAnswer: "Antwort generieren",
      adoptDraft: "Entwurf übernehmen",
    },
    translateEmail: {
      revert: "Zurück übersetzen",
    },
    loader: {
      loading: "Lädt ...",
    },
    prompts: {
      SpellCheck: "Rechtschreibprüfung",
      GrammarCheck: "Grammatikprüfung",
      Beautify: "Verschönern",
      OneMorePrompt: "Noch ein Eingabechip",
    },
    promptInput: {
      InputPlaceholder: "Geben Sie hier Ihre Eingabe ein",
    },
    Notification: {
      emptyPromptNotification:
        "Hier gibt es nichts zu überprüfen. Bitte schreiben Sie zuerst eine Nachricht in Outlook.",
      irrelevantPromptNotification: "Bitte stellen Sie eine relevante Frage.",
    },
    GeneratedDraft: {
      Response: "KI-Antwort (generierter Entwurf)",
      inputPlaceholder: "Geben Sie eine Eingabe ein",
      InsertButton: "Antwort einfügen",
      WorkingButton: "Ich arbeite daran ...",
      FooterText: "entwickelt von H24",
      FooterSubText: "Über Findus/H24",
    },
    MenuBar: {
      Restart: "Neustart",
      Report: "Ein Problem melden",
      Refresh: "Aktualisieren",
      ManageAddin: "Add-In verwalten",
    },
  },
  fr: {
    welcome: {
      title: `
          Bonjour, Utilisateur!
              `,
      subTitle: `
              Je suis Findus,
              votre assistant de messagerie
                  `,

      generateButton: "Générer un brouillon",
      footerText: "Réalisé par H24 Development",
      footerSubText: "À propos de Findus/H24",
    },
    draftEmail: {
      title: "Ce brouillon est généré par une IA générative, veuillez vérifier le contenu avant de l'envoyer.",
      agentInstructionLable: "Instructions pour l'assistant",
    },
    draftEmailButtons: {
      beautify: "Appliquer les instructions",
      generateAnswer: "Générer une réponse",
      adoptDraft: "Coller le brouillon",
    },
    translateEmail: {
      revert: "Traduire en arrière",
    },
    loader: {
      loading: "Chargement ...",
    },
    prompts: {
      SpellCheck: "Vérification orthographique",
      GrammarCheck: "Vérification grammaticale",
      Beautify: "Embellir",
      OneMorePrompt: "Un autre élément d'invite",
    },
    promptInput: {
      InputPlaceholder: "Tapez votre invite ici",
    },
    Notification: {
      emptyPromptNotification: "Il n'y a rien à vérifier ici. Veuillez d'abord taper un message dans Outlook.",
      irrelevantPromptNotification: "Veuillez poser une question pertinente.",
    },
    GeneratedDraft: {
      Response: "Réponse IA (brouillon généré)",
      inputPlaceholder: "Tapez une invite pour régénérer la réponse",
      InsertButton: "Insérer la réponse",
      WorkingButton: "Je m'en occupe ...",
      FooterText: " Réalisé par H24 Development",
      FooterSubText: "À propos de Findus/H24ai",
    },
    MenuBar: {
      Restart: "Redémarrer",
      Report: "Signaler un problème",
      Refresh: "Actualiser",
      ManageAddin: "Gérer le complément",
    },
  },
  ru: {
    welcome: {
      title: `
          Здравствуйте, пользователь!
              `,
      subTitle: `
              Я Findus,
              ваш почтовый ассистент
                  `,

      generateButton: "Создать черновик",
      footerText: "Создано H24 Development",
      footerSubText: "О Findus/H24",
    },
    draftEmail: {
      title: "Этот черновик создан генеративным ИИ, пожалуйста, проверьте содержимое перед отправкой.",
      agentInstructionLable: "Инструкции для ассистента",
    },
    draftEmailButtons: {
      beautify: "Применить инструкции",
      generateAnswer: "Сгенерировать ответ",
      adoptDraft: "Вставить черновик",
    },
    translateEmail: {
      revert: "Перевести обратно",
    },
    loader: {
      loading: "Загрузка ...",
    },
    prompts: {
      SpellCheck: "Проверка орфографии",
      GrammarCheck: "Проверка грамматики",
      Beautify: "Улучшить",
      OneMorePrompt: "Еще один элемент ввода",
    },
    promptInput: {
      InputPlaceholder: "Введите ваш запрос здесь",
    },
    Notification: {
      emptyPromptNotification: "Здесь нечего проверять. Пожалуйста, сначала напишите сообщение в Outlook.",
      irrelevantPromptNotification: "Пожалуйста, задайте актуальный вопрос.",
    },
    GeneratedDraft: {
      Response: "Ответ ИИ (сгенерированный черновик)",
      inputPlaceholder: "Введите запрос для регенерации ответа",
      InsertButton: "Вставить ответ",
      WorkingButton: "Работаю над этим ...",
      FooterText: " Создано H24 Development",
      FooterSubText: "О Findus/H24ai",
    },
    MenuBar: {
      Restart: "Перезапустить",
      Report: "Сообщить о проблеме",
      Refresh: "Обновить",
      ManageAddin: "Управление дополнением",
    },
  },
  it: {
    welcome: {
      title: `
          Ciao, Utente!
              `,
      subTitle: `
              Sono Findus,
              il tuo assistente email
                  `,

      generateButton: "Genera Bozza",
      footerText: "Realizzato da H24 Development",
      footerSubText: "Informazioni su Findus/H24",
    },
    draftEmail: {
      title: "Questa bozza è generata da IA generativa, si prega di rivedere il contenuto prima di inviare.",
      agentInstructionLable: "Istruzioni per l'assistente",
    },
    draftEmailButtons: {
      beautify: "Applica istruzioni",
      generateAnswer: "Genera risposta",
      adoptDraft: "Incolla bozza",
    },
    translateEmail: {
      revert: "Traduci indietro",
    },
    loader: {
      loading: "Caricamento ...",
    },
    prompts: {
      SpellCheck: "Controllo ortografico",
      GrammarCheck: "Controllo grammaticale",
      Beautify: "Abbellisci",
      OneMorePrompt: "Un altro suggerimento",
    },
    promptInput: {
      InputPlaceholder: "Scrivi qui il tuo suggerimento",
    },
    Notification: {
      emptyPromptNotification: "Non c'è niente da controllare qui. Per favore, scrivi prima un messaggio in Outlook.",
      irrelevantPromptNotification: "Per favore, fai una domanda pertinente.",
    },
    GeneratedDraft: {
      Response: "Risposta AI (bozza generata)",
      inputPlaceholder: "Scrivi un suggerimento per rigenerare la risposta",
      InsertButton: "Inserisci risposta",
      WorkingButton: "Ci sto lavorando ...",
      FooterText: " Realizzato da H24 Development",
      FooterSubText: "Informazioni su Findus/H24ai",
    },
    MenuBar: {
      Restart: "Riavvia",
      Report: "Segnala un problema",
      Refresh: "Aggiorna",
      ManageAddin: "Gestisci componente aggiuntivo",
    },
  },
  es: {
    welcome: {
      title: `
          ¡Hola, Usuario!
              `,
      subTitle: `
              Soy Findus,
              tu asistente de correo electrónico
                  `,

      generateButton: "Generar Borrador",
      footerText: "Hecho por H24 Development",
      footerSubText: "Acerca de Findus/H24",
    },
    draftEmail: {
      title: "Este borrador es generado por IA generativa, por favor revise el contenido antes de enviar.",
      agentInstructionLable: "Instrucciones para el asistente",
    },
    draftEmailButtons: {
      beautify: "Aplicar instrucciones",
      generateAnswer: "Generar respuesta",
      adoptDraft: "Pegar borrador",
    },
    translateEmail: {
      revert: "Traducir de vuelta",
    },
    loader: {
      loading: "Cargando ...",
    },
    prompts: {
      SpellCheck: "Revisión ortográfica",
      GrammarCheck: "Revisión gramatical",
      Beautify: "Embellecer",
      OneMorePrompt: "Otro elemento de sugerencia",
    },
    promptInput: {
      InputPlaceholder: "Escribe tu sugerencia aquí",
    },
    Notification: {
      emptyPromptNotification: "No hay nada que verificar aquí. Por favor, escriba un mensaje en Outlook primero.",
      irrelevantPromptNotification: "Por favor, haga una pregunta relevante.",
    },
    GeneratedDraft: {
      Response: "Respuesta de IA (borrador generado)",
      inputPlaceholder: "Escriba una sugerencia para regenerar la respuesta",
      InsertButton: "Insertar respuesta",
      WorkingButton: "Trabajando en ello ...",
      FooterText: " Hecho por H24 Development",
      FooterSubText: "Acerca de Findus/H24ai",
    },
    MenuBar: {
      Restart: "Reiniciar",
      Report: "Reportar un problema",
      Refresh: "Actualizar",
      ManageAddin: "Gestionar complemento",
    },
  },
  pt: {
    welcome: {
      title: `
          Olá, Usuário!
              `,
      subTitle: `
              Eu sou o Findus,
              seu assistente de e-mail
                  `,

      generateButton: "Gerar Rascunho",
      footerText: "Feito por H24 Development",
      footerSubText: "Sobre Findus/H24",
    },
    draftEmail: {
      title: "Este rascunho é gerado por IA generativa, por favor revise o conteúdo antes de enviar.",
      agentInstructionLable: "Instruções para o assistente",
    },
    draftEmailButtons: {
      beautify: "Aplicar instruções",
      generateAnswer: "Gerar resposta",
      adoptDraft: "Colar rascunho",
    },
    translateEmail: {
      revert: "Traduzir de volta",
    },
    loader: {
      loading: "Carregando ...",
    },
    prompts: {
      SpellCheck: "Verificação ortográfica",
      GrammarCheck: "Verificação gramatical",
      Beautify: "Embelezar",
      OneMorePrompt: "Mais um elemento de sugestão",
    },
    promptInput: {
      InputPlaceholder: "Digite sua sugestão aqui",
    },
    Notification: {
      emptyPromptNotification: "Não há nada para verificar aqui. Por favor, digite uma mensagem no Outlook primeiro.",
      irrelevantPromptNotification: "Por favor, faça uma pergunta relevante.",
    },
    GeneratedDraft: {
      Response: "Resposta de IA (rascunho gerado)",
      inputPlaceholder: "Digite uma sugestão para regenerar a resposta",
      InsertButton: "Inserir resposta",
      WorkingButton: "Trabalhando nisso ...",
      FooterText: " Feito por H24 Development",
      FooterSubText: "Sobre Findus/H24ai",
    },
    MenuBar: {
      Restart: "Reiniciar",
      Report: "Relatar um problema",
      Refresh: "Atualizar",
      ManageAddin: "Gerenciar complemento",
    },
  },
  zh: {
    welcome: {
      title: `
          你好，用户！
              `,
      subTitle: `
              我是 Findus，
              您的电子邮件助手
                  `,

      generateButton: "生成草稿",
      footerText: "由 H24 Development 制作",
      footerSubText: "关于 Findus/H24",
    },
    draftEmail: {
      title: "此草稿由生成式 AI 生成，发送前请检查内容。",
      agentInstructionLable: "对助手的指示",
    },
    draftEmailButtons: {
      beautify: "应用指示",
      generateAnswer: "生成答案",
      adoptDraft: "粘贴草稿",
    },
    translateEmail: {
      revert: "翻译回去",
    },
    loader: {
      loading: "加载中 ...",
    },
    prompts: {
      SpellCheck: "拼写检查",
      GrammarCheck: "语法检查",
      Beautify: "美化",
      OneMorePrompt: "再添加一个提示",
    },
    promptInput: {
      InputPlaceholder: "在此输入您的提示",
    },
    Notification: {
      emptyPromptNotification: "这里没有需要检查的内容。请先在 Outlook 中输入一条消息。",
      irrelevantPromptNotification: "请提出相关的问题。",
    },
    GeneratedDraft: {
      Response: "AI 回复（生成的草稿）",
      inputPlaceholder: "输入提示以重新生成回复",
      InsertButton: "插入回复",
      WorkingButton: "正在处理 ...",
      FooterText: " 由 H24 Development 制作",
      FooterSubText: "关于 Findus/H24ai",
    },
    MenuBar: {
      Restart: "重新启动",
      Report: "报告问题",
      Refresh: "刷新",
      ManageAddin: "管理插件",
    },
  },
};
