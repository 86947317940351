import React, { useContext, useEffect, useState } from "react";
import { TranslationContext } from "../..";
import "./Compose.css";
import { Frown, MessageSquare } from "lucide-react";
import PromptsInput from "./PromptsInput/PromptsInput";
import Prompts from "./Prompts/Prompts";
import Notification from "./Notification/Notification";
import MenuBar from "../MenuBar/MenuBar";
import { getBodyAsync } from "../Utilities/getComposeEmailBody";
import { rewriteDraftedReply } from "../Utilities/Rewrite";
import { handleInsertBodyContent } from "../Utilities/InsertToEmailBody";
import Footer from "../Footer/Footer";
import { FamilyRestroomOutlined } from "@mui/icons-material";

const Compose = () => {
  const language = useContext(TranslationContext);
  const [emptyPromptNotification, setEmptyPromptNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [irrelevantPromptNotification, setIrrelevantPromptNotification] = useState(true);
  const [inputValue, setInputValue] = useState("");

useEffect(() => {
  if (irrelevantPromptNotification) {
    setTimeout(() => {
      setIrrelevantPromptNotification(false);
    }, 3000);
  }
}, [irrelevantPromptNotification])

  const handlePromptClick = async (text) => {
    // setInputValue(text);

    const body = await getBodyAsync();
    if (body.trim() !== "") {
      console.log("Body text:", body); // Log when body text is fetched
      setEmptyPromptNotification(false);
      handleRewriteEmail(text);
    } else {
      console.log("Body is empty, trying again in 2 seconds...");
      setEmptyPromptNotification(true);
    }
  };
  const handleSend = async () => {
    console.log("Input Value:", inputValue);

    const body = await getBodyAsync();
    if (body.trim() !== "") {
      console.log("Body text:", body); // Log when body text is fetched
      setEmptyPromptNotification(false);
      handleRewriteEmail(inputValue);
    } else {
      console.log("Body is empty, trying again in 2 seconds...");
      setEmptyPromptNotification(true);
    }
  };
  const handleRewrite = () => {
    handleRewriteEmail();
  };

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default action (like form submission)

      const body = await getBodyAsync();
      if (body.trim() !== "") {
        console.log("Body text:", body); // Log when body text is fetched
        setEmptyPromptNotification(false);
        handleRewrite();
      } else {
        console.log("Body is empty, trying again in 2 seconds...");
        setEmptyPromptNotification(true);
      }
    }
  };
  const handleRewriteEmail = async (instruction) => {
    setIsLoading(true);
    try {
      const body = await getBodyAsync();

      const res = await rewriteDraftedReply(body, instruction);
      onInsert(res.answer);

      setIsLoading(false);

      console.log(res);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onInsert = (content) => {
    handleInsertBodyContent(content);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          paddingRight: "10px",
        }}
      >
        <MenuBar />
      </div>
      <div
        className="composeContainer"
        style={{
          paddingTop: irrelevantPromptNotification ? "0px" : emptyPromptNotification ? "5px" : "90px",
        }}
      >
        <img src="assets/Home.png" alt="" width={100} className="image" />
        <div>
          <div className="background-overlay"></div>

          <div className="content">
            <h1 className="heading">{language.welcome.title}</h1>
            <p className="subheading">{language.welcome.subTitle}</p>
            <div className="button-container">
              <>
                <Prompts
                  onPromptClick={handlePromptClick}
                  isIrrelevant={irrelevantPromptNotification}
                  isRelevant={emptyPromptNotification}
                />
                {isLoading ? (
                  <button
                    shape="circular"
                    style={{
                      position: "fixed",
                      borderRadius: "50px",
                      bottom: "45px",
                      width: "90%",
                      height: "48px",
                      backgroundColor: "#e2e3f7",
                      color: "#2E3A48",
                      right: "14px",
                      border: "1px solid lightgray",
                    }}
                    disabled="true"
                  >
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 35 35"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-2"
                        style={{
                          rotate: "180deg",
                          marginBottom: "8px",
                          marginRight: "6px",
                        }}
                        width={30}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                        />
                      </svg>{" "}
                      {language.GeneratedDraft.WorkingButton}
                    </>
                  </button>
                ) : (
                  <PromptsInput
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    onSend={handleSend}
                    isIrrelevant={irrelevantPromptNotification}
                    isRelevant={emptyPromptNotification}
                    onKeyDown={handleKeyDown}
                  />
                )}
              </>

              {(emptyPromptNotification || irrelevantPromptNotification) && (
                <Notification
                  text={
                    emptyPromptNotification
                      ? language.Notification.emptyPromptNotification
                      : language.Notification.irrelevantPromptNotification
                  }
                  Icon={emptyPromptNotification ? Frown : MessageSquare}
                  onClose={() => {
                    setIrrelevantPromptNotification(false);
                    setEmptyPromptNotification(false);
                  }}
                />
              )}
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Compose;
