import React, { useState, useContext } from "react";
import "./GeneratedDraft.css";
import { TranslationContext } from "../..";
import { useLocation } from "react-router-dom";
import { rewriteDraftedReply } from "../Utilities/Rewrite";
import { handleInsertBodyContent } from "../Utilities/InsertToEmailBody";
import { InfoRegular } from "@fluentui/react-icons";
import IconButton from "@mui/material/IconButton";
import { Send } from "lucide-react";
import MenuBar from "../MenuBar/MenuBar";
import Footer from "../Footer/Footer";

const GeneratedDraft = () => {
  const language = useContext(TranslationContext);
  const location = useLocation();
  const [replyContent, setReplyContent] = useState(location.state?.replyContent);
  const [isLoading, setIsLoading] = useState(false);
  const [instruction, setInstruction] = useState("");

  const handleRewrite = () => {
    handleRewriteEmail();
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default action (like form submission)
      handleRewrite(); // Call the send function
    }
  };
  const handleRewriteEmail = async () => {
    setIsLoading(true);
    try {
      const res = await rewriteDraftedReply(replyContent, instruction);
      setReplyContent(res.answer);
      console.log(res);
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onInsertReply = () => {
    handleInsertBodyContent(replyContent);
  };

  return (
    <div>
      <div
        style={{
          overflowY: "auto",
          padding: "24px",
          fontSize: "13px",
          height: "calc(100vh - 185px)", // Adjust height dynamically based on screen height
        }}
      >
        <p
          style={{
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {language.GeneratedDraft.Response} <InfoRegular fontSize={18} />
          <MenuBar />
        </p>

        <p dangerouslySetInnerHTML={{ __html: replyContent }} />
      </div>
      <hr />
      <div
        style={{
          backgroundColor: "white",
        }}
      >
        <div
          className="searchBox"
          style={{
            position: "fixed",
            bottom: "110px",
            backgroundColor: "white",
          }}
        >
          <input
            className="searchInput"
            type="text"
            value={instruction}
            onChange={(e) => setInstruction(e.target.value)}
            name=""
            onKeyDown={handleKeyDown}
            placeholder={language.GeneratedDraft.inputPlaceholder}
          />
          <IconButton
            onClick={handleRewrite}
            style={{
              backgroundColor: "#00e7fe",
              color: "#2E3A48",
              padding: "14px",
            }}
          >
            <Send />
          </IconButton>
        </div>
        <button
          shape="circular"
          style={{
            position: "fixed",
            borderRadius: "50px",

            bottom: "45px",

            width: "90%",
            height: "48px",
            backgroundColor: isLoading ? "#e2e3f7" : "#2E3A48",
            color: isLoading ? "#2E3A48" : "#00E7FE",
            right: "14px",
            border: isLoading ? "1px solid lightgray" : "none",
          }}
          onClick={onInsertReply}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 35 35"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-2"
                style={{
                  rotate: "180deg",
                  marginBottom: "8px",
                  marginRight: "6px",
                }}
                width={30}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                />
              </svg>{" "}
              {language.GeneratedDraft.WorkingButton}
            </>
          ) : (
            language.GeneratedDraft.InsertButton
          )}
        </button>
        <p
          style={{
            position: "fixed",
            bottom: "-20px",
            fontSize: "11px",
            right: "50px",
          }}
        >
          <Footer />
        </p>
      </div>
    </div>
  );
};

export default GeneratedDraft;
