import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@fluentui/react-components";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import GeneratedDraft from "./GeneratedDraft/GeneratedDraft";
import Home from "./Home";
import Compose from "./Compose/Compose";
const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

const App = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Router>
        <Routes>
          <Route exact path="/" element={Office.context.mailbox.item.conversationId == null ? <Compose /> : <Home />} />
          <Route exact path="/Compose" element={<Compose />} />
          <Route exact path="/GeneratedDraft" element={<GeneratedDraft />} />
        </Routes>
      </Router>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
};

export default App;
