import React, { useEffect, useState } from "react";
import { Button, makeStyles, Spinner } from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { fetchConversationMessages } from "../Utilities/FetchMessage";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

import { setBodyContent } from "../Utilities/InsertToEmailBody";
const useStyles = makeStyles({
  loaderoverlay: {
    position: "fixed",
    top: "50%",
    left: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "whitesmoke",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: ".5",
    zIndex: "10099",
  },
});

const DraftReply = () => {
  const navigate = useNavigate();
  const styles = useStyles();

  const [reply, setReply] = useState("");
  const [oldBody, setOldBody] = useState("");

  const [loader, setLoader] = useState(false);

  useEffect(() => {}, []);

  const fetchData = async () => {
    setLoader(true);
    try {
      const reply = await fetchConversationMessages();
      console.log(reply);
      setReply(reply.reply.answer);
      setOldBody(reply.parentMessage);

      console.log("Reply from AI", reply);
    } catch (error) {
      console.log("Error:", error);
      setReply("");
    } finally {
      setLoader(false);
    }
  };

  // const handleBackHome = () => {
  //   navigate("/home");
  // };

  const onReplyChange = (reply) => {
    setReply(reply);
  };

  const onCompose = (e) => {
    setBodyContent(reply);
  };

  const onGenerate = () => {
    fetchData();
  };

  return (
    <>
      {loader && (
        <div className={styles.loaderoverlay}>
          <Spinner size="extra-large" />
          <p>Laden...</p>
        </div>
      )}

      <p
        style={{
          fontWeight: "700",
          padding: "9px",
          marginTop: "63px",
        }}
      >
        Diese Frage wird durch künstliche Intelligenz beantwortet, bitte prüfen Sie den automatisch generierten Inhalt.
      </p>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div>
          <ReactQuill
            theme="snow"
            value={reply}
            onChange={onReplyChange}
            style={{
              minHeight: "100px",
              maxHeight: "250px",
              marginTop: "5px",
            }}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ color: [] }, { background: [] }],
                ["link", "image"],
                ["clean"],
              ],
            }}
            formats={[
              "header",
              "bold",
              "italic",
              "underline",
              "strike",
              "list",
              "bullet",
              "color",
              "background",
              "link",
              "image",
            ]}
          />
        </div>
        <br />
        <div
          style={{
            position: "fixed",
            zIndex: 100,
            bottom: "5px",
            left: "30%",
            marginTop: "70px",
          }}
        >
          {reply === "" ? (
            <Button style={{ backgroundColor: "#04EAF9" }} onClick={onGenerate}>
              Antwort generieren
            </Button>
          ) : (
            <Button style={{ backgroundColor: "#04EAF9" }} onClick={onCompose}>
              E-Mail senden
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default DraftReply;
