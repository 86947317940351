import React, { useContext } from "react";
import { TranslationContext } from "../../..";
import IconButton from "@mui/material/IconButton";
import { Send } from "lucide-react";
import "./PromptsInput";
const PromptsInput = ({ inputValue, setInputValue, onSend, isIrrelevant, isRelevant }) => {
  const language = useContext(TranslationContext);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSend();
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "50px",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "8px",
          background: "#ffffff",
          borderRadius: "50px",
          border: "1px solid #80808052",
          left: "4px",
          position: isIrrelevant ? "static" : "fixed",
          marginBottom: isIrrelevant ? "10px" : "0",
          bottom: isRelevant ? "207px" : "45px",
        }}
      >
        <input
          className="searchInput"
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder={language.promptInput.InputPlaceholder}
          onKeyDown={handleKeyDown}
        />
        <IconButton
          onClick={onSend}
          style={{
            backgroundColor: "#2E3A48",
            color: "#00e7fe",
            padding: "14px",
          }}
        >
          <Send />
        </IconButton>
      </div>
    </div>
  );
};

export default PromptsInput;
