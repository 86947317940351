import React, { useContext, useState } from "react";
import { TranslationContext } from "../..";
import { useNavigate } from "react-router-dom";
import { fetchConversationMessages } from "../Utilities/FetchMessage";
import "./Home.css";
import MenuBar from "../MenuBar/MenuBar";
import Footer from "../Footer/Footer";

const Home = () => {
  const language = useContext(TranslationContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleGenerateDraft = () => {
    fetchData();
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const reply = await fetchConversationMessages();
      navigate("/GeneratedDraft", { state: { replyContent: reply.reply.answer } });
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",

          justifyContent: "end",
          paddingRight: "10px",
        }}
      >
        <MenuBar />
      </div>
      <div className="container">
        <img src="assets/Home.png" alt="" width={100} className="image" />

        <div>
          <div className="background-overlay"></div>

          <div className="content">
            <h1 className="heading">{language.welcome.title}</h1>
            <p className="subheading">{language.welcome.subTitle}</p>
            <div className="button-container">
              <button
                className={`button ${isLoading ? "button-loading" : "button-normal"}`}
                onClick={handleGenerateDraft}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 35 35"
                      strokeWidth={1.5}
                      stroke="black"
                      className="icon"
                      width={30}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z"
                      />
                    </svg>
                    <span style={{ fontWeight: "700" }}>{language.GeneratedDraft.WorkingButton}</span>
                  </>
                ) : (
                  language.welcome.generateButton
                )}
              </button>

              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
