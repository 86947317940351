import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@fluentui/react-components";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import DraftReply from "./DraftReply";
const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

const App = () => {
  const styles = useStyles();
  // The list items are static and won't change at runtime,

  return (
    <div className={styles.root}>
      <Router>
        <Routes>

          <Route exact path="/" element={<DraftReply />} />
        </Routes>
      </Router>
    </div>
  );
};

App.propTypes = {
  title: PropTypes.string,
};

export default App;
