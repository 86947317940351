import { htmlToText } from "html-to-text";
import { replyEmail } from "./gptService";

export async function fetchConversationMessages() {
  return new Promise((resolve, reject) => {
    Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, async function (result) {
      if (result.status === "succeeded") {
        const accessToken = result.value;
        let conversationId = encodeURIComponent(Office.context.mailbox.item.conversationId);
        // if (conversationId.includes("+")) {
        //   conversationId.replaceAll("+", "-");
        // }
        // if (conversationId.includes("/")) {
        //   conversationId.replaceAll("/", "_");
        // }

        try {
          const messagesResponse = await fetch(
            `https://outlook.office.com/api/v2.0/me/messages?$filter=conversationId eq '${conversationId}'`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!messagesResponse.ok) {
            throw new Error(`Error fetching messages: ${messagesResponse.statusText}`);
          }

          const data = await messagesResponse.json();
          console.log(data.value);
          data.value.forEach((item) => {
            console.log(encodeURIComponent(item.internetMessageId));
          });

          const parentMessage = data.value[0].Body.Content;

          if (!parentMessage) {
            throw new Error("Parent message not found.");
          }

          const plainTextContent = htmlToText(parentMessage, {
            wordwrap: 130, // Optional: wrap text to specified width
          });

          const reply = await replyEmail(plainTextContent);
          resolve({ reply, parentMessage });
        } catch (error) {
          reject(`Error: ${error.message}`);
        }
      } else {
        reject("Failed to get callback token");
      }
    });
  });
}
