export async function setBodyContent(setBodyContent) {
  return new Promise((resolve, reject) => {
    let modifiedContent = setBodyContent.replaceAll(/<p>/g, "<div>").replaceAll(/<\/p>/g, "</div>");
    Office.context.mailbox.item.body.setAsync(setBodyContent, { coercionType: "html" }, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(true);
      } else {
        reject(result.error.message || "Failed to set body");
      }
    });
  });
}
