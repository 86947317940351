const env = process.env.NODE_ENV || "development";

const isProduction = env !== "development";

const constants = {
  API_URL: isProduction ? "" : "http://localhost:5000",
  DOMAIN_URL: isProduction ? "https://www.logimail.site" : "https://localhost:3000",
  REDIRECT_URL: isProduction
    ? "https://www.logimail.site/RedirectPage.html"
    : "https://localhost:3000/RedirectPage.html",
  CLIENT_ID: "202dd308-07a8-4fab-8801-57de23f72268",
};

export default constants;
